.login-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-image: linear-gradient(to right top, #ffffff, #fafbfc, #f3f7f7, #eef3f1, #eceee9);
}

.login-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 360px;
    width: 100%;
    margin-bottom: 100px;
}

.login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.login-logo img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-top: -5px;
}

.login-logo-text {
    margin-left: 10px;
}

.login-logo-title {
    font-family: OPTI !important;
    font-weight: 700;
    letter-spacing: -2px;
    text-shadow: 1px 1px 0 #fff, 2px 2px 0 #333;
    text-transform: uppercase;
    color: #333;
    font-size: 36px;
}

.login-logo-tagline {
    font-family: OPTI !important;
    font-weight: 700;
    letter-spacing: -2px;
    text-shadow: 1px 1px 0 #fff, 2px 2px 0 #333;
    text-transform: uppercase;
    color: #d87e29;
    font-size: 28px;
    margin-top: -5px;
}

.login-title {
    font-weight: 1000;
    font-size: 27px;
    text-align: center;
}

.login-text {
    font-size: 14px;
    color: #999;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
}

.form-box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 40px #D9D9D9;
    margin: 7px 0
}

.form-box>img {
    position: absolute;
    left: 12px;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.form-box input {
    width: 100%;
    height: 50px;
    padding: 0 40px;
    outline: none;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
}

.login-button {
    height: 50px;
    width: 100%;
    margin-top: 20px;
    background: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 1000;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.5s;
    font-size: 14px;
    box-shadow: 0 0 40px #D9D9D9;
    border: none;
    outline: none;
}

.login-button:hover {
    transform: scale(1.05);
}

.login-button span {
    font-size: 20px;
}

.login-secure {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.login-secure>img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.login-secure>img.hide {
    margin-top: 7px;
}

.login-link {
    font-size: 14px;
    margin-top: 20px;
    color: #999
}

.login-link a {
    color: var(--primary);
    font-weight: 700;
    text-decoration: none;
}



/* Not found page */
.not-found {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-image: linear-gradient(to right top, #ffffff, #fafbfc, #f3f7f7, #eef3f1, #eceee9);
}

.not-found-title {
    font-size: 36px;
    font-weight: 200;
    display: block;
}
.not-found-big {
    font-weight: 900;
    font-size: 100px;
}

.not-found a{
    margin-top: 20px;
    font-size: 14px;
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
}